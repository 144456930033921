import React from "react";
import LoginTela from "../components/LoginTela";

const SignInPage = () => {
  return (
    <>

      {/* LoginTela */}
      <LoginTela />

    </>
  );
};

export default SignInPage; 
